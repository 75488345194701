import React from "react";
import { Link } from "react-router-dom";
import {
  facebook,
  instagram,
  linkedin,
  logo,
  logoWhite,
  tiktok,
  x,
} from "../../assets/images";

const socialLinks = [
  {
    href: "https://www.linkedin.com/",
    icon: linkedin,
    alt: "LinkedIn",
  },
  {
    href: "https://facebook.com/",
    icon: facebook,
    alt: "Facebook",
  },
];

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8 ">
      <div className="flex justify-center items-center">
        <div className="container mx-auto px-4">
          <div className="flex flex-row justify-between space-x-2">
            <div>
              <h3 className="text-lg font-semibold mb-4 text-[#62bb06]">
                Contact Us
              </h3>
              <p>
                Phone: <span className="text-[12px]">+260 966 967252</span>
              </p>
              <p>
                Email:{" "}
                <span className="text-[12px]">
                  {" "}
                  info.rexropthsp@hazmatin.com
                </span>
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4 text-[#62bb06]">
                Address
              </h3>
              <p>
                <span className="text-[12px]">
                  Plot No: 2818 Vibhav Business
                </span>
              </p>
              <p>
                <span className="text-[12px]">Chingola Road, Kitwe</span>
              </p>
              <p>
                <span className="text-[12px]">10101</span>
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4 text-[#62bb06]">
                Quick Links
              </h3>
              <ul>
                <li>
                  <Link to="/about" className="hover:text-gray-300">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="hover:text-gray-300">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="hover:text-gray-300">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-8 flex flex-col items-center">
            <div className="flex space-x-4 mb-4">
              {socialLinks.map(({ href, icon, alt }) => (
                <a
                  key={alt}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={alt}
                >
                  <img src={icon} className="h-5 w-5" alt={alt} />
                </a>
              ))}
            </div>
            <p className="text-sm text-center">
              Hazmat Bosch Rexrothsp Zambia © {new Date().getFullYear()}. All
              rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
