import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "../../pages/home/assets/Images";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="pb-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 pt-2 lg:px-6 ">
        <div className="flex justify-between h-16">
          <div className="flex items-center ">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <img
                src="https://ik.imagekit.io/haircareai/cropped-website-logo%20(2).png?updatedAt=1729063155534"
                alt="HAZMAT Logo"
                className="h-[80px] w-auto rounded"
              />
            </Link>
          </div>
          <div className="hidden md:flex items-center space-x-7">
            <a
              href="https://www.boschrexroth.com/en/dc/technology/"
              className="hover:text-[#62bb06] font-semibold"
            >
              Technology
            </a>
            <a href="#section3" className="hover:text-[#62bb06] font-semibold">
              Solutions
            </a>
            <Link to="/board" className="hover:text-[#62bb06] font-semibold">
              Board
            </Link>
            <a href="#section2" className="hover:text-[#62bb06] font-semibold">
              About
            </a>
            <Link
              to="/contact"
              className="bg-[#62bb06] text-white font-semibold px-4 py-3 rounded hover:bg-[#62bb06] transition duration-300"
            >
              Contact us
            </Link>
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isMenuOpen ? "hidden" : "block"} h-10 w-10`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="black"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isMenuOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="black"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`${isMenuOpen ? "block" : "hidden"} md:hidden`}>
        <div className="px-2 pt-2 pb-3 flex flex-col space-y-7 sm:px-3">
          <a
            href="https://www.boschrexroth.com/en/dc/technology/"
            className="hover:text-[#62bb06] font-semibold"
          >
            Technology
          </a>
          <a href="#section3" className="hover:text-[#62bb06] font-semibold">
            Solutions
          </a>
          <Link to="/board" className="hover:text-[#62bb06] font-semibold">
            Board
          </Link>

          <a href="#section2" className="hover:text-[#62bb06] font-semibold">
            About
          </a>

          <a
            href="tel:+260 966 967252"
            className="block bg-[#62bb06] text-white font-bold px-3 py-2 rounded hover:bg-yellow-600 transition duration-300"
          >
            Contact us
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
