import React, { useState, useEffect } from "react";
import "../../../App.css";

const HeroSection: React.FC = () => {
  const words = ["innovation", "equipment", "repairs"];
  const [currentWord, setCurrentWord] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const typeWriter = () => {
      const current = words[currentIndex];
      if (isDeleting) {
        setCurrentWord(current.substring(0, currentWord.length - 1));
      } else {
        setCurrentWord(current.substring(0, currentWord.length + 1));
      }

      if (!isDeleting && currentWord === current) {
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && currentWord === "") {
        setIsDeleting(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
      }
    };

    const timer = setTimeout(typeWriter, isDeleting ? 50 : 150);
    return () => clearTimeout(timer);
  }, [currentWord, currentIndex, isDeleting]);

  return (
    <div className="relative text-gray-800">
      <div className="container mx-auto px-4 py-10 flex items-center justify-center">
        <div className="max-w-4xl text-center flex justify-center items-center flex-col noto-sans-jp-700 ">
          <img
            src="https://ik.imagekit.io/haircareai/cropped-website-logo%20(1)-min.png?updatedAt=1729062630159"
            alt="HAZMAT Logo"
            className="w-[100px] rounded"
          />
          <h1 className="text-[40px] lg:text-[50px] font-bold">
            Your partner in industrial
          </h1>{" "}
          <h1 className="text-[#62bb06] text-[45px] lg:text-[58px] font-bold mb-4">
            {currentWord}.
          </h1>
          <p className="text-[16px] lg:text-lg mb-8 text-gray-500 font-medium">
            <span className="text-[#62bb06]">Hazmat Innovations</span> empowers
            your business with cutting-edge assembly, filtration, and hydraulic
            technologies, delivered with top-tier expertise
          </p>
          <div className="flex justify-center">
            <a href="tel:+260 966 967252">
              <button className="bg-[#62bb06] text-white hover:text-[17px] px-16 py-4 rounded-full font-bold hover:py-5 hover:px-11 transition-all duration-300">
                Let's chat
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
