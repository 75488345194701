import { useState, useEffect } from "react";

const About = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const carouselImages = [
    "https://ik.imagekit.io/haircareai/Untitled%20design%20(24)-min.png?updatedAt=1728995639850",
    "https://ik.imagekit.io/haircareai/6.png?updatedAt=1729064678355",
    "https://ik.imagekit.io/haircareai/4.png?updatedAt=1729064676765",
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % carouselImages.length
      );
    }, 5000);

    return () => clearInterval(timer);
  }, []);
  return (
    <div id="section2" className="bg-black text-white py-16">
      <div className="container mx-auto px-4">
        <div className="flex justify-center flex-col items-center space-y-4">
          <div className="w-[50px] h-[5px] bg-[#62bb06] mt-6" />
          <h1 className="lg:text-4xl text-4xl font-semibold text-center pb-12 lg:pb-10">
            About us
          </h1>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 mb-8 text-center lg:text-start  lg:px-10 lg:mb-0 text-[15px] lg:text-[18px]">
            <p className="mb-4">
              <span className="text-[#62bb06] font-bold">
                HAZMAT INNOVATIONS LIMITED (HAZMAT)
              </span>{" "}
              was registered in 2004. It is a wholly Zambian owned company duly
              registered in accordance with the Companies Act of the Laws of
              Zambia under certificate of incorporation number 56055. We're
              passionate about revolutionizing industrial solutions. We've been
              at the forefront at cutting-edge technologies in assembly,
              filtration, and hydraulic systems.
            </p>
            <p className="mb-4">
              Our team of expert engineers and technicians is dedicated to
              providing top-tier expertise and personalized solutions to empower
              businesses across various industries.
            </p>
            <p className="">
              We believe in innovation, quality, and sustainability, ensuring
              that our clients stay ahead in an ever-evolving industrial
              landscape.
            </p>
          </div>
          <div className="lg:w-1/2 flex flex-col sm:flex-row items-center justify-center lg:justify-end space-y-4 sm:space-y-0 sm:space-x-4">
            <img
              src="https://ik.imagekit.io/haircareai/Untitled%20design%20(23)-min.png?updatedAt=1728995639879"
              alt="Team at work"
              className="w-full sm:w-1/2 h-auto object-cover rounded-lg shadow-lg"
            />
            <div className="w-full sm:w-1/2 h-auto sm:mt-16">
              <div className="flex flex-col sm:flex-row">
                {carouselImages.map((image, index) => (
                  <div
                    key={index}
                    className={`w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-2 transition-opacity duration-500 ${
                      index === currentImageIndex
                        ? "opacity-100"
                        : "opacity-0 hidden sm:block"
                    }`}
                  >
                    <img
                      src={image}
                      alt={`Our facility ${index + 1}`}
                      className="w-full h-auto object-cover rounded-lg shadow-lg"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
