import React from "react";

const WhyChooseUs = () => {
  return (
    <div>
      {" "}
      <div className="mt-6 bg-white py-10">
        <div className="flex justify-center flex-col items-center space-y-4">
          <div className="w-[50px] h-[5px] bg-[#62bb06] mt-6" />
          <h1 className="lg:text-5xl text-gray-800 text-4xl font-bold text-center pb-5 lg:mb-20 noto-sans-jp-700">
            Hazmat Innovations
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-2">
          <div className="flex justify-center items-center flex-col ">
            <div className="mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#1e1e1e"
                className="size-20"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM8.547 4.505a8.25 8.25 0 1 0 11.672 8.214l-.46-.46a2.252 2.252 0 0 1-.422-.586l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.211.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.654-.261a2.25 2.25 0 0 1-1.384-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.279-2.132Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <h3 className="text-xl font-bold mb-3 text-[#1e1e1e]">
              Global Standards
            </h3>
            <p className="text-gray-700 text-[15px] w-[250px] text-center ">
              All our products and services meet international standards,
              ensuring quality and reliability.
            </p>
            <div className="w-[80px] h-[10px] bg-[#62bb06] mt-4" />
          </div>
          <div className="p-6 flex justify-center items-center flex-col ">
            <div className="mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#1e1e1e"
                className="size-20"
              >
                <path
                  fillRule="evenodd"
                  d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <h3 className="text-xl font-bold mb-3 text-[#1e1e1e]">
              Zambian Expertise
            </h3>

            <p className="text-gray-700 text-[15px] w-[250px] text-center">
              We're a local company with deep knowledge of the industrial
              landscape in Zambia.
            </p>
            <div className="w-[80px] h-[10px] bg-[#62bb06] mt-4" />
          </div>
          <div className="p-6 flex justify-center items-center flex-col">
            <div className="mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#1e1e1e"
                className="size-20"
              >
                <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
                <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
              </svg>
            </div>
            <h3 className="text-xl font-bold mb-3 text-gray-800">
              Customer-Centric
            </h3>
            <p className="text-gray-700 text-[15px] w-[250px] text-center">
              We're driven by a desire to exceed client expectations through
              innovative solutions and top-notch customer service.
            </p>
            <div className="w-[80px] h-[10px] bg-[#62bb06] mt-4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
