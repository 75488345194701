import React, { useState } from "react";
import SlideInOnScroll from "../assets/animations/SlideInScroll";

const FAQSection = () => {
  const [openAccordions, setOpenAccordions] = useState<number[]>([]);

  const toggleAccordion = (index: number) => {
    setOpenAccordions((prevOpenAccordions) => {
      if (prevOpenAccordions.includes(index)) {
        return prevOpenAccordions.filter((i) => i !== index);
      } else {
        return [...prevOpenAccordions, index];
      }
    });
  };

  const faqData = [
    {
      question: "What services does Hazmat Bosch Rexrothsp Zambia offer?",
      answer:
        "We specialize in providing advanced engineering solutions, including assembly technology, filtration systems, hydraulic components, and repair services. We also supply a wide range of hydraulic hoses, fittings, and mobile hydraulic components.",
    },
    {
      question: "Where is your office located?",
      answer:
        "Our main office is located in Kitwe, Zambia, in the Light Industrial area. We aim to expand our presence across all provinces of Zambia.",
    },
    {
      question: "What industries do you serve?",
      answer:
        "We provide solutions for a variety of industries, including manufacturing, mining, automotive, and industrial engineering. Our technologies are designed to optimize performance in production lines and industrial operations.",
    },
    {
      question: "How can I contact Hazmat Bosch Rexrothsp Zambia?",
      answer:
        "You can reach us by email at info.rexrothsp@hazmatin.com or by phone at +260 212 270010. Our team is available to assist with inquiries, orders, or service requests.",
    },
  ];

  return (
    <div className="flex pt-8 flex-col justify-center items-center lg:py-[60px] px-4 lg:px-0 w-full pb-6 bg-black">
      <div>
        <div className=" text-center">
          <button className=" text-[#62bb06] text-[24px] md:text-[28px] px-8 py-4 font-bold  rounded-full  transition duration-300">
            Frequently Asked Questions
          </button>
        </div>
      </div>

      <div className="w-full lg:mt-[70px] mt-[1px] lg:px-20">
        <div className="flex flex-col">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className="border-b border-gray-300 last:border-none"
            >
              <div
                className="py-6 cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                <div className="flex flex-row items-center justify-between">
                  <div>
                    <h1 className="font-semibold text-[16px] text-gray-100">
                      {faq.question}
                    </h1>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`size-6 transition-transform ${
                        openAccordions.includes(index) ? "rotate-45" : ""
                      }`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </div>
                </div>
                {openAccordions.includes(index) && (
                  <div className="pt-4">
                    <p className="text-gray-100 text-[15px]">{faq.answer}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
