import React from "react";
import Footer from "../../components/footer/Footer";
import FAQSection from "../home/components/FAQSection";

interface BoardMember {
  name: string;
  position: string;
  phone: string;
  email: string;
}

const boardMembers: BoardMember[] = [
  {
    name: "Mr. Mike Muyoro",
    position: "Board Member",
    phone: "+260 966 967 252",
    email: "mike.rexrothsp@hazmatin.com",
  },
  {
    name: "Mr. Victor Siame",
    position: "Board Member",
    phone: "+260 966 787 106",
    email: "victor.rexrothsp@hazmatin.com",
  },
];

const Board: React.FC = () => {
  return (
    <div className="w-full bg-black text-white py-16">
      <div className="container mx-auto">
        <div className="flex justify-center flex-col items-center space-y-4 mb-12">
          <div className="w-[50px] h-[5px] bg-[#62bb06]" />
          <h1 className="text-4xl font-semibold text-center noto-sans-jp-700">
            Our Board
          </h1>
        </div>

        <div className="flex flex-col items-center space-y-8">
          {boardMembers.map((member, index) => (
            <div
              key={index}
              className="bg-gray-900 rounded-lg overflow-hidden shadow-lg p-6 text-center w-full max-w-md"
            >
              <h2 className="text-2xl font-semibold mb-2">{member.name}</h2>
              <p className="text-green-500 mb-4">{member.position}</p>
              <p className="text-gray-300 mb-2">{member.phone}</p>
              <p className="text-gray-300">{member.email}</p>
            </div>
          ))}
        </div>
      </div>
      <FAQSection />
      <Footer />
    </div>
  );
};

export default Board;
