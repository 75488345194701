import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const services = [
  {
    title: "Assembly Technology",
    description:
      "Optimize your production line with our advanced transfer systems, manual production setups, and electric drives.",
    image:
      "https://ik.imagekit.io/haircareai/Untitled%20design%20(14)-min.png?updatedAt=1728570191749",
  },
  {
    title: "Filtration Solutions",
    description:
      "Ensure optimal performance with our range of filtration technologies, including process filtration, hydraulic, and lube filtration.",
    image:
      "https://ik.imagekit.io/haircareai/Untitled%20design%20(15)-min.png?updatedAt=1728570784811",
  },
  {
    title: "Hydraulic Systems",
    description:
      "Power your business with our high-performance hydraulic motors, pumps, and valves, custom-designed for your industrial needs.",
    image:
      "https://ik.imagekit.io/haircareai/hydraulic-min.png?updatedAt=1728570192116",
  },
  {
    title: "Hose & Fittings",
    description:
      "We supply a wide range of hydraulic hoses, fittings, and mobile hydraulic components, ensuring safety and efficiency in your operations.",
    image:
      "https://ik.imagekit.io/haircareai/fittings-min.png?updatedAt=1728570192930",
  },
  {
    title: "Repairs & Maintenance",
    description:
      "Keep your equipment running smoothly with our expert repair services, backed by a commitment to quality assurance and timely delivery.",
    image:
      "https://ik.imagekit.io/haircareai/repair-min.png?updatedAt=1728570192444",
  },
];

const OurServices = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setVisibleItems(1);
      } else if (window.innerWidth < 1024) {
        setVisibleItems(2);
      } else {
        setVisibleItems(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const nextSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % (services.length - visibleItems + 1)
    );
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + (services.length - visibleItems + 1)) %
        (services.length - visibleItems + 1)
    );
  };

  return (
    <div id="section3" className="w-full bg-black text-white py-7 lg:py-16">
      <div className="container mx-auto">
        <div className="flex justify-center flex-col items-center space-y-4">
          <div className="w-[50px] h-[5px] bg-[#62bb06] mt-6 lg:mt-0" />
          <h1 className="lg:text-4xl text-4xl font-semibold text-center mb-12 lg:pb-10 noto-sans-jp-700">
            Our solutions
          </h1>
        </div>

        <div className="relative px-8">
          <div className="flex overflow-hidden">
            {services
              .slice(currentIndex, currentIndex + visibleItems)
              .map((service, index) => (
                <div
                  key={index}
                  className="w-full px-2 flex-shrink-0"
                  style={{ flex: `0 0 ${100 / visibleItems}%` }}
                >
                  <div className="relative h-60 hover:cursor-pointer overflow-hidden shadow-lg flex justify-center items-center">
                    <img
                      src={
                        service.image || "https://via.placeholder.com/400x300"
                      }
                      alt={service.title}
                      className="absolute h-[200px] hover:h-[230px] object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
                    <div className="absolute inset-0 p-6 flex flex-col justify-end">
                      <h2 className="text-2xl hover:text-3xl text-center font-semibold mb-2 transition-all duration-300 noto-sans-jp-700">
                        <a href="tel:+260 966 967252">{service.title}</a>
                      </h2>
                      <p className="text-[13px] hover:text-[15px] text-center text-gray-300 transition-all duration-300">
                        <a href="tel:+260 966 967252"> {service.description}</a>
                      </p>
                      <p className="text-[12px] hover:text-[13px] text-[#62bb06] text-center font-semibold underline transition-all duration-300">
                        <a href="tel:+260 966 967252"> Get In-touch</a>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <button
            className="absolute left-0 top-20 -translate-y-1/2 z-10 bg-white bg-opacity-90 p-2 rounded-full"
            onClick={prevSlide}
          >
            <ChevronLeft className="w-6 h-6 text-black" />
          </button>
          <button
            className="absolute right-0 top-20 -translate-y-1/2 z-10 bg-white bg-opacity-90 p-2 rounded-full"
            onClick={nextSlide}
          >
            <ChevronRight className="w-6 h-6 text-black" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
