import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navbar/NavBar";
import { hero } from "./assets/Images";
import About from "./components/About";
import FAQSection from "./components/FAQSection";
import HeroSection from "./components/HeroSection";
import OurServices from "./components/OurServices";
import WhyChooseUs from "./components/WhyChooseUs";

const Home = () => {
  return (
    <div>
      <section className="border-b border-gray-300">
        <NavBar />
      </section>
      <div>
        <HeroSection />
        <section>
          <OurServices />
        </section>
        <section>
          <WhyChooseUs />
        </section>
        <section>
          <About />
        </section>
        <section>
          <FAQSection />
        </section>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
